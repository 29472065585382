<template>
  <div class="rs-bold-highlight">
    <EditorSimpleText v-if="isEditing" :value="value" @input="emitUpdate" />
    <template v-else>
      <ReportContentOutput :value="value" />
    </template>
  </div>
</template>

<script>
import EditorSimpleText from "@/components/editors/EditorSimpleText";
import ReportContentOutput from "@/components/ReportContentOutput.vue";

export default {
  components: {
    EditorSimpleText,
    ReportContentOutput,
  },
  props: {
    isEditing: {
      type: Boolean,
      required: false,
    },
    value: {
      type: String,
      required: false,
    },
  },

  methods: {
    emitUpdate(e) {
      this.$emit("update:value", e);
    },
  },
};
</script>

<style lang="scss" scoped></style>
